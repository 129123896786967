import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setToken } from "../planetSlice";
import { setScreenMode } from "../screenSlice";
import { setToastMessage } from "../planetSlice";
// import LogoutIcon from "../../../img/svg/logout-svgrepo-com.svg";
// import Logout from "../../../img/svg/logout-icon.jpg";

const Profile = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  console.log(user);

  const onNavClick = (e) => {
    e.preventDefault();
    dispatch(setScreenMode(Number(e.target.id)));
  };

  
  if (!user) return (<div className="unlogedUser">
                          <p>Looks like you're not loged in...</p>
                               </div>);

  return (
    <>
      <div className="accountWrapper">
        <div className="account">
          <div className="accountLinks">
            <a href="/#" onClick={onNavClick} id="11" className="underlineLink">PROFILE</a>
          </div>
          <div className="customerContacts">
            <div className="greeting">
              <h3>Hello, {user.firstName}! </h3>
              <button
                onClick={() => {
                  dispatch(setScreenMode(9));
                  dispatch(setToken(null));
                  dispatch(setToastMessage("You are loged out now"));
                  localStorage.clear();
                }}> Log out
                {/* <img src={Logout} alt="logout-icon" /> */}
              </button>
            </div>
            <div className="personalInfo">
              <div>
                <h4>First Name / Last Name :</h4>
                <p> {user.firstName} {user.lastName}</p>
              </div>
              <div>
                <p> <small>E-mail :</small> </p>
                <p>{user.email}</p>
              </div>
              <div className="editLink">
                <a href="/#" onClick={onNavClick} id="15"> EDIT</a>
              </div>
            </div>
            <div className="customerAddress">
              <h4>Address :</h4>
              <p>{user.addressLine1} {user.addressLine2}, {user.postcode} {user.city}, {user.country}</p>
            </div>
            <div className="customerAddress">
              <h4>Phone Number :</h4>
              <p>{user.phoneNumber}</p>
            </div>
            <div className="editLink">
              <a href="/#" onClick={onNavClick} id="16"> EDIT
              </a>
              {/* and button and redirect to Register Details if they are not present? */}
              {/* <a href="/#" onClick={onNavClick} id="12"> ADD INFO
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
