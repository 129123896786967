
//  taking images from public folder, intro images

 export const intro = [
  {
    title: `Perseverance Rover`,
    img: `./img/introImages/perseverance-rover.jpg`,
  },
  {
    title: `Curiosity Rover`,
    img: `./img/introImages/curiosity-rover.jpg`,
  },   
  {
    title: `Ingenuity helicopter`,
    img: `./img/introImages/ingenuity1.jpg`,
  },
  {
    title: `Odyssey Orbiter`,
    img: `./img/introImages/odyssey-orbiter.jpg`,
  }
];