import React from "react";
import Intro from "./Intro";



const Home = () => {
  return (
    <>
    <div className="homeContainer">
      <Intro />
      
    </div>
    
    </>
  );
};

export default Home;
