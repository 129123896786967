export const url = `https://api.marsmessengers.co.uk`;

// export const url = `http://localhost:6001`;

//  ToggleSubscribe.jsx:
// line 17: await axios.get(`http://localhost:6001/account/users`)
// line 32: await axios.post(`http://localhost:6001/account/subscribe`)
// line 42: await axios.delete(`http://localhost:6001/account/subscribe`)

//  Subscribe.jsx
// line 19: await axios.post(`http://localhost:6001/account/subscribe`)

// CustomerAccount.jsx
// line 22: await axios.get(`http://localhost:6001/account/users`)

//  EditUserInfo.jsx
//  line 42: await axios.patch(`http://localhost:6001/account/update`)

// EditUserDetails.jsx
//  line 42-43: await axios.patch(`http://localhost:6001/account/update_details`)

//  Login.jsx
//  line 30-31: await axios.post(`http://localhost:6001/account/login`)

//  Register.jsx
//  line 24: await axios.post(`http://localhost:6001/account/register`)

//  RegisterDetails.jsx
//  line 24: await axios.post(`http://localhost:6001/account/details)

//   Insomnia
